import { axios } from '@/utils/request'

/**
 * 查詢訪問日誌
 *
 * @author yubaoshan
 * @date 2020/5/19 11:57
 */
export function sysVisLogPage (parameter) {
  return axios({
    url: '/sysVisLog/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 查詢操作日誌
 *
 * @author yubaoshan
 * @date 2020/5/19 11:57
 */
export function sysOpLogPage (parameter) {
  return axios({
    url: '/sysOpLog/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 清空訪問日誌
 *
 * @author yubaoshan
 * @date 2020/6/23 23:09
 */
export function sysVisLogDelete (parameter) {
  return axios({
    url: '/sysVisLog/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 清空登錄日誌
 *
 * @author yubaoshan
 * @date 2020/6/23 23:09
 */
export function sysOpLogDelete (parameter) {
  return axios({
    url: '/sysOpLog/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 導出登錄日誌
 *
 * @author yubaoshan
 * @date 2021/5/30 18:03
 */
export function sysVisLogExport (parameter) {
  return axios({
    url: '/sysVisLog/export',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}

/**
 * 導出操作日誌
 *
 * @author yubaoshan
 * @date 2021/5/30 18:03
 */
export function sysOpLogExport (parameter) {
  return axios({
    url: '/sysOpLog/export',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}
