//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysVisLogDelete, sysVisLogExport, sysVisLogPage } from '@/api/modular/system/logManage'
import { Ellipsis, STable, XCard, XDown } from '@/components'
import moment from 'moment'
import detailsVislog from './details'
export default {
    components: {
        XDown,
        XCard,
        STable,
        Ellipsis,
        detailsVislog,
    },
    data() {
        return {
            advanced: false,
            // 查詢參數
            queryParam: {},
            // 表頭
            columns: [
                // {
                //   title: '日誌名稱',
                //   dataIndex: 'name',
                //   scopedSlots: { customRender: 'name' },
                // },
                {
                    title: '訪問類型',
                    dataIndex: 'visType',
                    scopedSlots: { customRender: 'visType' },
                },
                {
                    title: '是否成功',
                    dataIndex: 'success',
                    scopedSlots: { customRender: 'success' },
                },
                {
                    title: 'ip',
                    dataIndex: 'ip',
                },
                {
                    title: '瀏覽器',
                    dataIndex: 'browser',
                },
                {
                    title: '訪問時間',
                    dataIndex: 'visTime',

                    scopedSlots: { customRender: 'visTime' },
                },
                {
                    title: '訪問人',
                    dataIndex: 'account',
                },
                {
                    title: '訪問企業',
                    dataIndex: 'corpName',
                },
                {
                    title: '詳情',
                    dataIndex: 'action',
                    width: '150px',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                return sysVisLogPage(Object.assign(parameter, this.switchingDate())).then((res) => {
                    return res.data
                })
            },
            defaultExpandedKeys: [],
            visTypeDict: [],
            successDict: [],
        }
    },
    /**
     * 相當於html的onload方法，進來初始化
     */
    created() {
        this.sysDictTypeDropDown()
    },
    methods: {
        moment,
        /**
         * 獲取字典數據
         */
        sysDictTypeDropDown() {
            this.visTypeDict = this.$options.filters['dictData']('vis_type')
            this.successDict = this.$options.filters['dictData']('yes_or_no')
        },
        /**
         * 查詢參數組裝
         */
        switchingDate() {
            const dates = this.queryParam.dates
            if (dates != null) {
                this.queryParam.searchBeginTime = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
                this.queryParam.searchEndTime = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
                if (dates.length < 1) {
                    delete this.queryParam.searchBeginTime
                    delete this.queryParam.searchEndTime
                }
            }
            const obj = JSON.parse(JSON.stringify(this.queryParam))
            delete obj.dates
            return obj
        },
        /**
         * 批量導出
         */
        batchExport() {
            sysVisLogExport().then((res) => {
                this.$refs.batchExport.downloadfile(res)
            })
        },
        /**
         * 清空日誌
         */
        sysVisLogDelete() {
            sysVisLogDelete().then((res) => {
                if (res.success) {
                    this.$message.success('清空成功')
                    this.$refs.table.refresh(true)
                } else {
                    this.$message.error('清空失敗：' + res.message)
                }
            })
        },
        toggleAdvanced() {
            this.advanced = !this.advanced
        },
        // 重置
        reset() {
            this.queryParam = {}
            this.$refs.table.refresh(true)
        },
    },
}
